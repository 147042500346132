@font-face {
    font-family: "Ubuntu";
    src: url(/public/assets/Ubuntu-Regular.ttf);
}

@font-face {
    font-family: "Sofia";
    src: url(/public/assets/Sofia.ttf);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    --clr-header: var(--clr-blue);
    --clr-footer: var(--clr-blue);
    --clr-button: #2E8618;
    --clr-text: #FFF;
    --clr-card: #FFF;
    --clr-active: blue;
    --clr-blue: #273E94;
    --clr-warning: #FFB715;
    --clr-inactive: #DEDEDE;
    --clr-btn: #FF7798;
}

html,
body {
    max-width: 100vw;
    font-family: Sofia;
    font-size: 20px;
    overflow-x: clip;
}

#root,
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2 {
    text-align: center;
    margin: 1rem;
}

img.logo {
    max-height: 96px;
    width: auto;
}

header {
    min-height: 5em;
    background-color: var(--clr-header);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
}

.container {
    width: 100%;
    max-width: min(1200px, 100vw);
    padding: 0 1.25em;
}

header .container,
footer .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner {
    display: flex;
    align-items: center;
    max-height: 100px;
    width: 100%;
    margin-right: auto;
}

.banner span {
    font-size: 2.25rem;
    font-family: Ubuntu;
    color: var(--clr-text)
}

footer .banner {
    max-height: 60px;
}

footer .banner img {
    max-height: 64px;
}

footer .banner span {
    font-size: 1.33rem;
}

header nav {
    display: flex;
}

header nav ul {
    flex-direction: row;
    margin-left: auto;
}

nav ul {
    display: flex;
    gap: 1.5em
}

nav li,
footer li {
    list-style: none;
}

.stores-links {
    display: flex;
    max-width: 100%;
}

.stores-links {
    flex-shrink: 2;
}

.stores-links img {
    max-height: 60px;
    width: auto;
    object-fit: contain;
}

@media screen and (max-width: 420px) {
    .stores-links img {
        max-height: 14vw;
    }
}

main p {
    margin: 1rem 0.25rem;
}

.today {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.today p {
    margin-bottom: unset;
}

.today span {
    color: var(--clr-button);
    font-family: Ubuntu;
    font-size: 1.5rem;
    font-weight: 700;
}

.partners {
    display: grid;
    width: 100%;
    max-width: 1200px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-template-rows: auto;
    grid-gap: 2rem;
    place-items: stretch center;
}

@media screen and (max-width: 600px) {
    .partners {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }
}

.card {
    width: 100%;
    background-color: var(--clr-card);
    display: grid;
    place-items: stretch;
    grid-auto-flow: row;
    padding: 0.5rem;
    max-width: min(400px, calc(100vw - 2.5rem));
    height: max-content;
    border-radius: 0.75rem;
    border: 1px solid var(--clr-inactive);
    overflow: hidden;
}

.card hr {
    width: calc(100% + 2rem);
    margin: 0.5rem -1rem;
    border-bottom: none;
    border-left: none;
    border-right: none;
}

.card-logo {
    aspect-ratio: 4/3;
    background-position: 50%;
    background-size: contain;
    margin: -1rem -1rem .5rem;
    width: calc(100% + 2rem);
    display: grid;
    place-items: center;
    padding: 2ch;
}

.card-logo img {
    object-fit: contain;
    width: 100%;
    height: auto;
}

.card-row {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

.card-row + .card-row {
    margin-top: 0.5rem
}

.card-row.description {
    justify-content: space-around;
    height: 45px;
}

.card-row.description span {
    text-align: center;
}

.card a {
    display: grid;
    place-items: center;
    border-radius: 0.25rem;
    background-color: var(--clr-button);
    margin: 1rem auto 0;
    padding: 0.5rem 1rem;
    font-size: 0.93rem;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--clr-text);
}

article {
    padding: 1rem 3rem;
    text-align: justify;
}

footer {
    background-color: var(--clr-footer);
    width: 100%;
    padding: 1rem 0 1.5rem;
}

footer .container > * {
    flex-basis: 100%;
}

@media screen and (max-width: 800px) {
    header .container,
    footer .container {
        flex-direction: column;
    }
}

footer ul {
    margin: auto 10rem;
}

@media screen and (max-width: 500px) {
    html,
    body {
        font-size: 4vw;
    }
}

footer .moto {
    color: var(--clr-text);
}

.popper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #5555;
    display: grid;
    place-items: center;
    backdrop-filter: blur(5px);
}

.popper-wrapper {
    display: grid;
    grid-gap: 0.5rem;
    position: relative;
    background-color: var(--clr-text);
    max-height: 80vh;
    max-width: 75%;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 0.75rem;
    margin: 1rem;
}

.popper-header {
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    background-color: var(--clr-text);
    border-radius: 0.75rem;
}

#popper-close {
    position: absolute;
    display: grid;
    place-items: center;
    top: 0.25rem;
    right: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    border-radius: 50%
}

.popper-text {
    text-align: center;
    padding: 0.5rem;
    grid-column: 1/-1;
    max-width: min(400px, calc(100vw - 2.5rem));
}

.popper-cards {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    max-height: 70vh;
    padding: 0 2rem 1rem;
    overflow-y: scroll;
}

@media screen and (max-width: 620px) {
    .popper-cards {
        grid-template-columns: 1fr;
        max-width: 600px;
    }
}

.download-prompt {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 450px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    background-color: #FFF;
    color: #000;
    text-align: center;
    padding: 20px 40px;
    z-index: 9999;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    filter: drop-shadow(0 -2px 4px gray);
}

.download-prompt p {
    grid-column: 1 / -1;
}

.download-prompt span {
    grid-column: 1 / 2;
    place-self: center start;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1ch;
}

.download-prompt img {
    --size: 48px;
    display: block;
    object-fit: contain;
    width: var(--size);
    height: var(--size);
}

.download-prompt a, .download-prompt button {
    color: #FFF;
    background-color: var(--clr-button);
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    margin-left: 10px;
    grid-column: 2/3;
    place-self: center end;
    border-radius: 100px;
    width: 120px;
    border: 0;
}

.download-prompt a:hover {
    text-decoration: underline;
}

.download-prompt .download-prompt__to-browser {
    background-color: lightgray;
    color: black;
}
